<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="12" class="my-4 my-sm-8 my-md-0" style="margin-top: 100px !important;">
          <div style="width: 100%;">
            <div class="login__container" style="margin: auto !important;">
              <div class="login__inner">
                <div class="login__image">
                  <img src="main_logo.png" style="width: 140px; margin: 0 80px;" alt="Logo Oscuro">
                </div>
                <div class="login__header pt-5">
                    <h4>Inicia Sesión</h4>
                </div>
                <div class="login__subscribe d-flex justify-space-between align-center pb-8">
                    <p class="text-subtitle-2 ma-0" style="font-size: 12px;">{{welcome}}</p>
                    <!-- <a href="https://somosorderly.com/orderly-facturador-gratuito-pruebalo-gratis" class="custom-transform-class text-none font-weight-regular px-1">Regístrate aquí.</a> -->
                    <!-- <a href="/#/signup" class="custom-transform-class text-none font-weight-regular px-1">{{cta}}.</a> -->
                </div>
                <div class="login__form">
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        v-model="email"
                        label="Correo Electrónico"
                        placeholder=""
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12" class="input-group">
                      <CInput
                        :type="passwordFieldType"
                        v-model="password"
                        placeholder="Contraseña"
                        aria-label="Contraseña"
                        aria-describedby="togglePassword"
                        style="width: 259px;"
                      />
                      <div class="input-group-append">
                        <CButton @click="togglePasswordVisibility" color="light" class="btn btn-outline-secondary" style="height: 35px; padding-top: 4px;">
                          <CIcon :name="passwordIcon" style="color: #666666;" />
                        </CButton>
                      </div>
                    </CCol>
                  </CRow>
                  <br />
                  <CRow>
                    <CCol sm="12">
                      <CButton type="button" color="primary" style="width: 100%" @click="login">INICIAR SESIÓN</CButton>
                    </CCol>
                  </CRow>
                </div>
                <div class="login__actions d-flex justify-space-between py-2">
                    <div class="login__remind" style="font-size: 12px;">
                    </div>
                    <div class="login__recovery-password" style="width: 100%">
                        <a href="/#/forget" class="font-weight-regular px-1" style="font-size: 12px; float: right !important;">¿Olvidaste tu contraseña?</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import router from '../../../router/index'
import login from '../../../services/login';
import ws from '../../../services/general';
import store from '../../../store'
import axios from 'axios'
import { cilLockLocked, cilLockUnlocked } from '@coreui/icons';

export default {
  name: 'Login',
  data: function () {
      return {
          email: '',
          password: '',
          showPassword: false,
          remind: false,
          image: '',
          welcome: '',
          cta: '',
          desktop: true
      }
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    passwordIcon() {
      return this.showPassword ? 'cil-lock-unlocked' : 'cil-lock-locked';
    },
  },
  mounted: async function() {
    let response = await ws.getParameters(); 

    if(response.type == "success"){
      let parameters = response.data;

      let param1 = this.$findElementByName(parameters, "LOGIN_IMAGE");
      this.image = param1.value;

      let param2 = this.$findElementByName(parameters, "LOGIN_WELCOME");
      this.welcome = param2.value;

      let param3 = this.$findElementByName(parameters, "LOGIN_CTA");
      this.cta = param3.value;
    }

    let navegador = navigator.userAgent;
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
        this.desktop = false;
    } else {
        this.desktop = true;
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    login: async function(){
      let params = {
        email: this.email,
        password: this.password,
        desktop: this.desktop,
        device_name: navigator.userAgent
      };

      let response = await login.login(params);

      localStorage.removeItem("school_tab");
      localStorage.removeItem("travel_tab");

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("token", data.access_token);

        axios.defaults.headers.common['access-token'] = data.access_token;

        if(data.role.slug == "normal"){
          window.location.href = "/#/app"; return;
        }

        window.location.href = "/#/"; return;
      }
      else{
        alert(response.message);
      }
    },
  }
}
</script>
